import { SagaIterator } from "redux-saga";
import { call, put } from "redux-saga/effects";
import { push } from "react-router-redux";
import { AxiosError } from "axios";

import { FreeUsersService } from "modules/free-users/services";
import {
  fetchFreeUsersSuccess,
  fetchFreeUsersError,
  fetchSingleAccountSuccess,
  fetchSingleAccountError,
  createNewAccountSuccess,
  createNewAccountError,
  editFreeModulesSuccess,
  editFreeModulesError,
  editAccountSuccess,
  editAccountError,
  deleteAccountSuccess,
  deleteAccountError,
  loginWithFreeAccountSuccess,
  loginWithFreeAccountError,
  toggleActivationFreeAccountSuccess,
  toggleActivationFreeAccountError,
  fetchChurnTypesSuccess,
  fetchChurnTypesError,
} from "modules/free-users/store/actions/actionCreators";
import { Error } from "modules/free-users/store/actions/actionTypes";
import * as actionTypes from "modules/free-users/store/actions/actionTypes";

const freeUsersService = FreeUsersService.getInstance();

export function* fetchFreeUsersWorker(
  action: actionTypes.FetchFreeUsers
): SagaIterator {
  try {
    const { data } = yield call(
      freeUsersService.fetchUsers,
      action.payload?.params
    );

    yield put(fetchFreeUsersSuccess(data));
  } catch (e) {
    yield put(fetchFreeUsersError((e as AxiosError).response?.data as Error));
  }
}

export function* fetchSingleAccountWorker(
  action: actionTypes.FetchSingleAccount
): SagaIterator {
  try {
    const { data } = yield call(
      freeUsersService.fetchAccountDetails,
      action.payload?.companyId
    );

    yield put(fetchSingleAccountSuccess({ company: data }));
  } catch (e) {
    if ((e as AxiosError).response?.status === 404) {
      yield put(push("/free-accounts"));
    }

    yield put(
      fetchSingleAccountError((e as AxiosError).response?.data as Error)
    );
  }
}

export function* createFreeAccountWorker(
  action: actionTypes.CreateFreeAccount
): SagaIterator {
  try {
    const { data } = yield call(
      freeUsersService.createAccount,
      action.payload.newFreeAccount
    );

    yield put(createNewAccountSuccess({ newAccountId: data.companyID }));
    yield put(push(`/free-account/view/${data.companyID}/details`));
  } catch (e) {
    yield put(createNewAccountError((e as AxiosError).response?.data as Error));
  }
}

export function* editFreeModulesWorker({
  payload: {
    data: { key, value, companyId },
  },
}: actionTypes.EditFreeModules): SagaIterator {
  try {
    const { data } = yield call(freeUsersService.editCompanyModules, {
      companyId,
      key,
      value,
    });

    yield put(editFreeModulesSuccess({ key, value, companyId: data.id }));
  } catch (e) {
    yield put(editFreeModulesError((e as AxiosError).response?.data as Error));
  }
}

export function* editAccountWorker(
  action: actionTypes.EditFreeAccount
): SagaIterator {
  try {
    yield call(freeUsersService.editAccount, action.payload.updatedCompany);

    yield put(editAccountSuccess());
  } catch (e) {
    yield put(editAccountError((e as AxiosError).response?.data as Error));
  }
}

export function* deleteAccountWorker(
  action: actionTypes.DeleteFreeAccount
): SagaIterator {
  try {
    yield call(freeUsersService.deleteAccount, action.payload.companyId);

    yield put(deleteAccountSuccess());
  } catch (e) {
    yield put(deleteAccountError((e as AxiosError).response?.data as Error));
  }
}

export function* loginWithFreeAccountWorker(
  action: actionTypes.LoginWithFreeAccount
): SagaIterator {
  try {
    const { data } = yield call(
      freeUsersService.loginWithCompany,
      action.payload.companyId
    );

    yield put(loginWithFreeAccountSuccess(data));
  } catch (e) {
    yield put(
      loginWithFreeAccountError((e as AxiosError).response?.data as Error)
    );
  }
}

export function* toggleActivationFreeAccountWorker(
  action: actionTypes.ToggleActivationFreeAccount
): SagaIterator {
  try {
    const { data } = yield call(
      freeUsersService.toggleCompanyActivated,
      action.payload!
    );

    yield put(toggleActivationFreeAccountSuccess(data.data));
  } catch (e) {
    yield put(
      toggleActivationFreeAccountError(
        (e as AxiosError).response?.data as Error
      )
    );
  }
}

export function* fetchChurnTypesWorker(): SagaIterator {
  try {
    const { data } = yield call(freeUsersService.fetchChurnTypes);

    yield put(fetchChurnTypesSuccess(data));
  } catch (e) {
    if ((e as AxiosError<Error>).response?.data) {
      yield put(
        fetchChurnTypesError((e as AxiosError<Error>).response?.data as Error)
      );
    }
  }
}
