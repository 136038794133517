import { SagaIterator } from "redux-saga";
import { call, put } from "redux-saga/effects";
import { IntegrationsErrorsService } from "../../service/integrations-errors.service";
import {
  fetchIntegrationsErrorsError,
  fetchIntegrationsErrorsSuccess,
  setIntegrationsErrorsIsLoading,
  setIntegrationsErrorsTotalCount,
  updateIntegrationsErrorError,
  updateIntegrationsErrorIsLoading,
} from "../actions/actionCreators";
import {
  FetchIntegrationsErrorsQueryParams,
  IntegrationsErrorsAction,
  UpdateIntegrationErrorPayload,
} from "../../types";
import { AxiosError } from "axios";

const service = IntegrationsErrorsService.getInstance();

export function* fetchIntegrationsErrorsWorker({
  payload,
}: IntegrationsErrorsAction<FetchIntegrationsErrorsQueryParams>): SagaIterator {
  try {
    yield put(setIntegrationsErrorsIsLoading(true));
    const data = yield call(service.fetchErrorsList, payload);
    yield put(fetchIntegrationsErrorsSuccess(data.list));
    yield put(setIntegrationsErrorsTotalCount(data.totalCount));
  } catch (e) {
    yield put(fetchIntegrationsErrorsError(getCyclrErrorMessageFromError(e)));
  } finally {
    yield put(setIntegrationsErrorsIsLoading(false));
  }
}

export function* updateIntegrationsErrorWorker({
  payload,
}: IntegrationsErrorsAction<UpdateIntegrationErrorPayload>): SagaIterator {
  try {
    yield put(updateIntegrationsErrorIsLoading(true));
    yield call(service.updateError, payload);
  } catch (e) {
    yield put(updateIntegrationsErrorError(getCyclrErrorMessageFromError(e)));
  } finally {
    yield put(updateIntegrationsErrorIsLoading(false));
  }
}

function getCyclrErrorMessageFromError(e: AxiosError): string {
  return (
    e.isAxiosError &&
    ((e as AxiosError).response?.data as { message: Array<string> }).message
      ? ((e as AxiosError).response?.data as { message: Array<string> }).message
      : e.message
  ).toString();
}
