export enum CompanyManagementActionsTypes {
  SHOW_COMPANIES_PAGE = "[COMPANY_MANAGEMENT_MODULE] SHOW_COMPANIES_PAGE",

  FETCH_COMPANIES = "[COMPANY_MANAGEMENT_MODULE] FETCH_COMPANIES",
  FETCH_COMPANIES_SUCCESS = "[COMPANY_MANAGEMENT_MODULE] FETCH_COMPANIES_SUCCESS",
  FETCH_COMPANIES_ERROR = "[COMPANY_MANAGEMENT_MODULE] FETCH_COMPANIES_ERROR",

  FETCH_SINGLE_COMPANY = "[COMPANY_MANAGEMENT_MODULE] FETCH_SINGLE_COMPANY",
  FETCH_SINGLE_COMPANY_SUCCESS = "[COMPANY_MANAGEMENT_MODULE] FETCH_SINGLE_COMPANY_SUCCESS",
  FETCH_SINGLE_COMPANY_ERROR = "[COMPANY_MANAGEMENT_MODULE] FETCH_SINGLE_COMPANY_ERROR",

  FETCH_COMPANY_LOGS = "[COMPANY_MANAGEMENT_MODULE] FETCH_COMPANY_LOGS",
  FETCH_COMPANY_LOGS_SUCCESS = "[COMPANY_MANAGEMENT_MODULE] FETCH_COMPANY_LOGS_SUCCESS",
  FETCH_COMPANY_LOGS_ERROR = "[COMPANY_MANAGEMENT_MODULE] FETCH_COMPANY_LOGS_ERROR",

  ADD_COMPANY = "[COMPANY_MANAGEMENT_MODULE] ADD_COMPANY",
  ADD_COMPANY_SUCCESS = "[COMPANY_MANAGEMENT_MODULE] ADD_COMPANY_SUCCESS",
  ADD_COMPANY_ERROR = "[COMPANY_MANAGEMENT_MODULE] ADD_COMPANY_ERROR",

  EDIT_COMPANY_MODULES = "[COMPANY_MANAGEMENT_MODULE] EDIT_COMPANY_MODULES",
  EDIT_COMPANY_MODULES_SUCCESS = "[COMPANY_MANAGEMENT_MODULE] EDIT_COMPANY_MODULES_SUCCESS",
  EDIT_COMPANY_MODULES_ERROR = "[COMPANY_MANAGEMENT_MODULE] EDIT_COMPANY_MODULES_ERROR",

  GET_CURRENT_LIMITS = "[COMPANY_MANAGEMENT_MODULE] GET_CURRENT_LIMITS",
  GET_CURRENT_LIMITS_SUCCESS = "[COMPANY_MANAGEMENT_MODULE] GET_CURRENT_LIMITS_SUCCESS",
  GET_CURRENT_LIMITS_ERROR = "[COMPANY_MANAGEMENT_MODULE] GET_CURRENT_LIMITS_ERROR",

  EDIT_COMPANY_LIMITS = "[COMPANY_MANAGEMENT_MODULE] EDIT_COMPANY_LIMITS",
  EDIT_COMPANY_LIMITS_SUCCESS = "[COMPANY_MANAGEMENT_MODULE] EDIT_COMPANY_LIMITS_SUCCESS",
  EDIT_COMPANY_LIMITS_ERROR = "[COMPANY_MANAGEMENT_MODULE] EDIT_COMPANY_LIMITS_ERROR",

  EDIT_SINGLE_COMPANY_LIMITS = "[COMPANY_MANAGEMENT_MODULE] EDIT_SINGLE_COMPANY_LIMITS",
  EDIT_SINGLE_COMPANY_LIMITS_SUCCESS = "[COMPANY_MANAGEMENT_MODULE] EDIT_SINGLE_COMPANY_LIMITS_SUCCESS",
  EDIT_SINGLE_COMPANY_LIMITS_ERROR = "[COMPANY_MANAGEMENT_MODULE] EDIT_SINGLE_COMPANY_LIMITS_ERROR",

  EDIT_COMPANY = "[COMPANY_MANAGEMENT_MODULE] EDIT_COMPANY",
  EDIT_COMPANY_SUCCESS = "[COMPANY_MANAGEMENT_MODULE] EDIT_COMPANY_SUCCESS",
  EDIT_COMPANY_ERROR = "[COMPANY_MANAGEMENT_MODULE] EDIT_COMPANY_ERROR",

  EDIT_COMPANY_SETTING = "[COMPANY_MANAGEMENT_MODULE] EDIT_COMPANY_SETTING",
  EDIT_COMPANY_SETTING_SUCCESS = "[COMPANY_MANAGEMENT_MODULE] EDIT_COMPANY_SETTING_SUCCESS",
  EDIT_COMPANY_SETTING_ERROR = "[COMPANY_MANAGEMENT_MODULE] EDIT_COMPANY_SETTING_ERROR",

  REMOVE_COMPANY = "[COMPANY_MANAGEMENT_MODULE] REMOVE_COMPANY",
  REMOVE_COMPANY_SUCCESS = "[COMPANY_MANAGEMENT_MODULE] REMOVE_COMPANY_SUCCESS",
  REMOVE_COMPANY_ERROR = "[COMPANY_MANAGEMENT_MODULE] REMOVE_COMPANY_ERROR",

  SEND_CONTRACT = "[COMPANY_MANAGEMENT_MODULE] SEND_CONTRACT",
  SEND_CONTRACT_SUCCESS = "[COMPANY_MANAGEMENT_MODULE] SEND_CONTRACT_SUCCESS",
  SEND_CONTRACT_ERROR = "[COMPANY_MANAGEMENT_MODULE] SEND_CONTRACT_ERROR",

  FETCH_CURRENCIES = "[COMPANY_MANAGEMENT_MODULE] FETCH_CURRENCIES",
  FETCH_CURRENCIES_SUCCESS = "[COMPANY_MANAGEMENT_MODULE] FETCH_CURRENCIES_SUCCESS",
  FETCH_CURRENCIES_ERROR = "[COMPANY_MANAGEMENT_MODULE] FETCH_CURRENCIES_ERROR",

  FETCH_CHURN_TYPES = "[COMPANY_MANAGEMENT_MODULE] FETCH_CHURN_TYPES",
  FETCH_CHURN_TYPES_SUCCESS = "[COMPANY_MANAGEMENT_MODULE] FETCH_CHURN_TYPES_SUCCESS",
  FETCH_CHURN_TYPES_ERROR = "[COMPANY_MANAGEMENT_MODULE] FETCH_CHURN_TYPES_ERROR",

  FETCH_CLEARBIT = "[COMPANY_MANAGEMENT_MODULE] FETCH_CLEARBIT",
  FETCH_CLEARBIT_SUCCESS = "[COMPANY_MANAGEMENT_MODULE] FETCH_CLEARBIT_SUCCESS",
  FETCH_CLEARBIT_ERROR = "[COMPANY_MANAGEMENT_MODULE] FETCH_CLEARBIT_ERROR",

  ENABLE_DOMAIN = "[COMPANY_MANAGEMENT_MODULE] ENABLE_DOMAIN",
  ENABLE_DOMAIN_SUCCESS = "[COMPANY_MANAGEMENT_MODULE] ENABLE_DOMAIN_SUCCESS",
  ENABLE_DOMAIN_ERROR = "[COMPANY_MANAGEMENT_MODULE] ENABLE_DOMAIN_ERROR",

  DELETE_USER_FROM_COMPANY = "[COMPANY_MANAGEMENT_MODULE] DELETE_USER_FROM_COMPANY",
  DELETE_USER_FROM_COMPANY_SUCCESS = "[COMPANY_MANAGEMENT_MODULE] DELETE_USER_FROM_COMPANY_SUCCESS",
  DELETE_USER_FROM_COMPANY_ERROR = "[COMPANY_MANAGEMENT_MODULE] DELETE_USER_FROM_COMPANY_ERROR",

  DISABLE_DOMAIN = "[COMPANY_MANAGEMENT_MODULE] DISABLE_DOMAIN",
  DISABLE_DOMAIN_SUCCESS = "[COMPANY_MANAGEMENT_MODULE] DISABLE_DOMAIN_SUCCESS",
  DISABLE_DOMAIN_ERROR = "[COMPANY_MANAGEMENT_MODULE] DISABLE_DOMAIN_ERROR",

  FETCH_BOTS = "[COMPANY_MANAGEMENT_MODULE] FETCH_BOTS",
  FETCH_BOTS_SUCCESS = "[COMPANY_MANAGEMENT_MODULE] FETCH_BOTS_SUCCESS",
  FETCH_BOTS_ERROR = "[COMPANY_MANAGEMENT_MODULE] FETCH_BOTS_ERROR",

  SAVE_BOT = "[COMPANY_MANAGEMENT_MODULE] SAVE_BOT",
  SAVE_BOT_SUCCESS = "[COMPANY_MANAGEMENT_MODULE] SAVE_BOT_SUCCESS",
  SAVE_BOT_ERROR = "[COMPANY_MANAGEMENT_MODULE] SAVE_BOT_ERROR",

  SEND_OLD_CONTRACT = "[COMPANY_MANAGEMENT_MODULE] SEND_OLD_CONTRACT",
  SEND_OLD_CONTRACT_SUCCESS = "[COMPANY_MANAGEMENT_MODULE] SEND_OLD_CONTRACT_SUCCESS",
  SEND_OLD_CONTRACT_ERROR = "[COMPANY_MANAGEMENT_MODULE] SEND_OLD_CONTRACT_ERROR",

  SYNC_COMPANY_WITH_PLANHAT = "[COMPANY_MANAGEMENT_MODULE] SYNC_COMPANY_WITH_PLANHAT",
  SYNC_COMPANY_WITH_PLANHAT_SUCCESS = "[COMPANY_MANAGEMENT_MODULE] SYNC_COMPANY_WITH_PLANHAT_SUCCESS",
  SYNC_COMPANY_WITH_PLANHAT_ERROR = "[COMPANY_MANAGEMENT_MODULE] SYNC_COMPANY_WITH_PLANHAT_ERROR",

  SYNC_COMPANY_WITH_YOUNIUM = "[COMPANY_MANAGEMENT_MODULE] SYNC_COMPANY_WITH_YOUNIUM",
  SYNC_COMPANY_WITH_YOUNIUM_SUCCESS = "[COMPANY_MANAGEMENT_MODULE] SYNC_COMPANY_WITH_YOUINUM_SUCCESS",
  SYNC_COMPANY_WITH_YOUNIUM_ERROR = "[COMPANY_MANAGEMENT_MODULE] SYNC_COMPANY_WITH_YOUNIUM_ERROR",

  FETCH_SALES_PEOPLE = "[COMPANY_MANAGEMENT_MODULE] FETCH_SALES_PEOPLE",
  FETCH_SALES_PEOPLE_SUCCESS = "[COMPANY_MANAGEMENT_MODULE] FETCH_SALES_PEOPLE_SUCCESS",
  FETCH_SALES_PEOPLE_ERROR = "[COMPANY_MANAGEMENT_MODULE] FETCH_SALES_PEOPLE_ERROR",

  FETCH_COMPANY_CONTRACTS = "[COMPANY_MANAGEMENT_MODULE] FETCH_COMPANY_CONTRACTS",
  FETCH_COMPANY_CONTRACTS_SUCCESS = "[COMPANY_MANAGEMENT_MODULE] FETCH_COMPANY_CONTRACTS_SUCCESS",
  FETCH_COMPANY_CONTRACTS_ERROR = "[COMPANY_MANAGEMENT_MODULE] FETCH_COMPANY_CONTRACTS_ERROR",

  FETCH_INDUSTRIES = "[COMPANY_MANAGEMENT_MODULE] FETCH_INDUSTRIES",
  FETCH_INDUSTRIES_SUCCESS = "[COMPANY_MANAGEMENT_MODULE] FETCH_INDUSTRIES_SUCCESS",
  FETCH_INDUSTRIES_ERROR = "[COMPANY_MANAGEMENT_MODULE] FETCH_INDUSTRIES_ERROR",

  FETCH_INDUSTRY_SUB_CLASSES = "[COMPANY_MANAGEMENT_MODULE] FETCH_INDUSTRY_SUB_CLASSES",
  FETCH_INDUSTRY_SUB_CLASSES_SUCCESS = "[COMPANY_MANAGEMENT_MODULE] FETCH_INDUSTRY_SUB_CLASSES_SUCCESS",
  FETCH_INDUSTRY_SUB_CLASSES_ERROR = "[COMPANY_MANAGEMENT_MODULE] FETCH_INDUSTRY_SUB_CLASSES_ERROR",

  FETCH_COMPANIES_TO_SWITCH = "[COMPANY_MANAGEMENT_MODULE] FETCH_COMPANIES_TO_SWITCH",
  FETCH_COMPANIES_TO_SWITCH_SUCCESS = "[COMPANY_MANAGEMENT_MODULE] FETCH_COMPANIES_TO_SWITCH_SUCCESS",
  FETCH_COMPANIES_TO_SWITCH_ERROR = "[COMPANY_MANAGEMENT_MODULE] FETCH_COMPANIES_TO_SWITCH_ERROR",

  RESEND_ACTIVATION = "[COMPANY_MANAGEMENT_MODULE] RESEND_ACTIVATION",
  RESEND_ACTIVATION_SUCCESS = "[COMPANY_MANAGEMENT_MODULE] RESEND_ACTIVATION_SUCCESS",
  RESEND_ACTIVATION_ERROR = "[COMPANY_MANAGEMENT_MODULE] RESEND_ACTIVATION_ERROR",
  DUPLICATE_COMPANY = "[COMPANY_MANAGEMENT_MODULE] DUPLICATE_COMPANY",
  DUPLICATE_COMPANY_ERROR = "[COMPANY_MANAGEMENT_MODULE] DUPLICATE_COMPANY_ERROR",
  DUPLICATE_COMPANY_SUCCESS = "[COMPANY_MANAGEMENT_MODULE] DUPLICATE_COMPANY_SUCCESS",

  TOGGLE_COMPANY_ACTIVATED = "[COMPANY_MANAGEMENT_MODULE] TOGGLE_COMPANY_ACTIVATED",
  TOGGLE_COMPANY_ACTIVATED_SUCCESS = "[COMPANY_MANAGEMENT_MODULE] TOGGLE_COMPANY_ACTIVATED_SUCCESS",
  TOGGLE_COMPANY_ACTIVATED_ERROR = "[COMPANY_MANAGEMENT_MODULE] TOGGLE_COMPANY_ACTIVATED_ERROR",

  TOGGLE_CONTRACT_MODAL_VISIBLE = "[COMPANY_MANAGEMENT_MODULE] TOGGLE_CONTRACT_MODAL_VISIBLE",

  CREATE_CONTRACT = "[COMPANY_MANAGEMENT_MODULE] CREATE_CONTRACT",
  CREATE_CONTRACT_SUCCESS = "[COMPANY_MANAGEMENT_MODULE] CREATE_CONTRACT_SUCCESS",
  CREATE_CONTRACT_ERROR = "[COMPANY_MANAGEMENT_MODULE] CREATE_CONTRACT_ERROR",

  EDIT_CONTRACT = "[COMPANY_MANAGEMENT_MODULE] EDIT_CONTRACT",
  EDIT_CONTRACT_SUCCESS = "[COMPANY_MANAGEMENT_MODULE] EDIT_CONTRACT_SUCCESS",
  EDIT_CONTRACT_ERROR = "[COMPANY_MANAGEMENT_MODULE] EDIT_CONTRACT_ERROR",

  BULK_SAVE_CONTRACTS = "[COMPANY_MANAGEMENT_MODULE] BULK_SAVE_CONTRACTS",
  BULK_SAVE_CONTRACTS_SUCCESS = "[COMPANY_MANAGEMENT_MODULE] BULK_SAVE_CONTRACTS_SUCCESS",
  BULK_SAVE_CONTRACTS_ERROR = "[COMPANY_MANAGEMENT_MODULE] BULK_SAVE_CONTRACTS_ERROR",

  FETCH_PLATFORMS = "[COMPANY_MANAGEMENT_MODULE] FETCH_PLATFORMS",
  FETCH_PLATFORMS_SUCCESS = "[COMPANY_MANAGEMENT_MODULE] FETCH_PLATFORMS_SUCCESS",
  FETCH_PLATFORMS_ERROR = "[COMPANY_MANAGEMENT_MODULE] FETCH_PLATFORMS_ERROR",

  CREATE_PLATFORM = "[COMPANY_MANAGEMENT_MODULE] CREATE_PLATFORM",
  CREATE_PLATFORM_SUCCESS = "[COMPANY_MANAGEMENT_MODULE] CREATE_PLATFORM_SUCCESS",
  CREATE_PLATFORM_ERROR = "[COMPANY_MANAGEMENT_MODULE] CREATE_PLATFORM_ERROR",

  REMOVE_PLATFORM = "[COMPANY_MANAGEMENT_MODULE] REMOVE_PLATFORM",
  REMOVE_PLATFORM_SUCCESS = "[COMPANY_MANAGEMENT_MODULE] REMOVE_PLATFORM_SUCCESS",
  REMOVE_PLATFORM_ERROR = "[COMPANY_MANAGEMENT_MODULE] REMOVE_PLATFORM_ERROR",

  EDIT_PLATFORM = "[COMPANY_MANAGEMENT_MODULE] EDIT_PLATFORM",
  EDIT_PLATFORM_SUCCESS = "[COMPANY_MANAGEMENT_MODULE] EDIT_PLATFORM_SUCCESS",
  EDIT_PLATFORM_ERROR = "[COMPANY_MANAGEMENT_MODULE] EDIT_PLATFORM_ERROR",

  FETCH_CONTRACT_HISTORIES = "[COMPANY_MANAGEMENT_MODULE] FETCH_CONTRACT_HISTORIES",
  FETCH_CONTRACT_HISTORIES_SUCCESS = "[COMPANY_MANAGEMENT_MODULE] FETCH_CONTRACT_HISTORIES_SUCCESS",
  FETCH_CONTRACT_HISTORIES_ERROR = "[COMPANY_MANAGEMENT_MODULE] FETCH_CONTRACT_HISTORIES_ERROR",

  CLEAN_CONTRACT_HISTORIES = "[COMPANY_MANAGEMENT_MODULE] CLEAN_CONTRACT_HISTORIES",

  POPULATE_HISTORICAL_LEADS = "[COMPANY_MANGEMENT_MODULE] POPULATE_HISTORICAL_LEADS",
  POPULATE_HISTORICAL_LEADS_SUCCESS = "[COMPANY_MANGEMENT_MODULE] POPULATE_HISTORICAL_LEADS_SUCCESS",
  POPULATE_HISTORICAL_LEADS_ERROR = "[COMPANY_MANGEMENT_MODULE] POPULATE_HISTORICAL_LEADS_ERROR",

  GET_AI_MODELS = "[COMPANY_MANAGEMENT_PROFILE] GET_AI_MODELS",
  GET_AI_MODELS_SUCCESS = "[COMPANY_MANAGEMENT_PROFILE] GET_AI_MODELS_SUCCESS",
  GET_AI_MODELS_ERROR = "[COMPANY_MANAGEMENT_PROFILE] GET_AI_MODELS_ERROR",

  GET_SELECTED_AI_MODELS = "[COMPANY_MANAGEMENT_PROFILE] GET_SELECTED_AI_MODELS",
  GET_SELECTED_AI_MODELS_SUCCESS = "[COMPANY_MANAGEMENT_PROFILE] GET_SELECTED_AI_MODELS_SUCCESS",
  GET_SELECTED_AI_MODELS_ERROR = "[COMPANY_MANAGEMENT_PROFILE] GET_SELECTED_AI_MODELS_ERROR",

  POST_SELECTED_AI_MODELS = "[COMPANY_MANAGEMENT_PROFILE] POST_SELECTED_AI_MODELS",
  POST_SELECTED_AI_MODELS_SUCCESS = "[COMPANY_MANAGEMENT_PROFILE] POST_SELECTED_AI_MODELS_SUCCESS",
  POST_SELECTED_AI_MODELS_ERROR = "[COMPANY_MANAGEMENT_PROFILE] POST_SELECTED_AI_MODELS_ERROR",

  DELETE_SELECTED_AI_MODELS = "[COMPANY_MANAGEMENT_PROFILE] DELETE_SELECTED_AI_MODELS",
  DELETE_SELECTED_AI_MODELS_SUCCESS = "[COMPANY_MANAGEMENT_PROFILE] DELETE_SELECTED_AI_MODELS_SUCCESS",
  DELETE_SELECTED_AI_MODELS_ERROR = "[COMPANY_MANAGEMENT_PROFILE] DELETE_SELECTED_AI_MODELS_ERROR",

  SET_LIMIT = "[COMPANY_MANAGEMENT_PROFILE] SET_LIMIT",
  SET_LIMIT_SUCCESS = "[COMPANY_MANAGEMENT_PROFILE] SET_LIMIT_SUCCESS",
  SET_LIMIT_ERROR = "[COMPANY_MANAGEMENT_PROFILE] SET_LIMIT_ERROR",

  CONNECT_COMPANIES = "[COMPANY_MANAGEMENT_PROFILE] CONNECT_COMPANIES",
  CONNECT_COMPANIES_SUCCESS = "[COMPANY_MANAGEMENT_PROFILE] CONNECT_COMPANIES_SUCCESS",
  CONNECT_COMPANIES_ERROR = "[COMPANY_MANAGEMENT_PROFILE] CONNECT_COMPANIES_ERROR",

  GET_SM_CONNECTION = "[COMPANY_MANAGEMENT_PROFILE] GET_SM_CONNECTION",
  GET_SM_CONNECTION_SUCCESS = "[COMPANY_MANAGEMENT_PROFILE] GET_SM_CONNECTION_SUCCESS",
  GET_SM_CONNECTION_ERROR = "[COMPANY_MANAGEMENT_PROFILE] GET_SM_CONNECTION_ERROR",

  GET_SM_COMPANY_BY_TOKEN = "[COMPANY_MANAGEMENT_PROFILE] GET_SM_COMPANY_BY_TOKEN",
  GET_SM_COMPANY_BY_TOKEN_SUCCESS = "[COMPANY_MANAGEMENT_PROFILE] GET_SM_COMPANY_BY_TOKEN_SUCCESS",
  GET_SM_COMPANY_BY_TOKEN_ERROR = "[COMPANY_MANAGEMENT_PROFILE] GET_SM_COMPANY_BY_TOKEN_ERROR",

  DELETE_SM_CONNECTION = "[COMPANY_MANAGEMENT_PROFILE] DELETE_SM_CONNECTION",
  DELETE_SM_CONNECTION_SUCCESS = "[COMPANY_MANAGEMENT_PROFILE] DELETE_SM_CONNECTION_SUCCESS",

  CLEAR_ERRORS = "[COMPANY_MANAGEMENT_PROFILE] CLEAR_ERRORS",
}
