import { SagaIterator } from "redux-saga";
import { put, call } from "redux-saga/effects";
import { push } from "react-router-redux";

import { AuthService } from "modules/auth/services";
import {
  signInSuccess,
  signInError,
  signOutSuccess,
  signOutError,
  fetchMeOAuthSuccess,
  fetchMeOAuthError,
  fetchMeUserSuccess,
  fetchMeUserError,
  sendEmailSuccess,
  sendEmailError,
  restorePasswordSuccess,
  restorePasswordError,
  fetchUserByCodeSuccess,
  fetchUserByCodeError,
} from "modules/auth/store/actions/actionCreators";
import {
  SignIn,
  RestorePassword,
  FetchUserByCode,
} from "modules/auth/store/actions/actionTypes";

const authService = AuthService.getInstance();

export function* signInWorker({ payload }: SignIn): SagaIterator {
  try {
    const { data } = yield call(authService.postLogin, {
      email: payload.email,
      password: payload.password,
    });

    localStorage.setItem("token", data.token);

    yield put(signInSuccess());
    yield put(push("/"));
  } catch (e: any) {
    yield put(signInError((e as any).response.data.errors));
  }
}

export function* signOutWorker(): SagaIterator {
  try {
    const res = yield call(authService.logout);
    if (res) {
      yield put(signOutSuccess());
    }
  } catch (e: any) {
    yield put(signOutError((e as any).response.data.errors));
  }
}

export function* fetchMeOAuthWorker(): SagaIterator {
  try {
    const { data } = yield call(authService.fetchMe);
    yield put(fetchMeOAuthSuccess(data.oauth));
  } catch (e: any) {
    yield put(fetchMeOAuthError((e as any).response.data.errors));
  }
}

export function* fetchMeUserWorker(): SagaIterator {
  try {
    const { data } = yield call(authService.fetchMe);
    const redirectUrl = window.sessionStorage.getItem("redirectUrl");
    window.sessionStorage.removeItem("redirectUrl");
    yield put(fetchMeUserSuccess(data.user));
    if (redirectUrl) {
      yield put(push(redirectUrl));
    }
  } catch (e: any) {
    window.sessionStorage.setItem("redirectUrl", window.location.pathname);
    yield put(fetchMeUserError(e.response.data.errors));
  }
}

export function* sendEmailWorker(): SagaIterator {
  try {
    const { data } = yield call(authService.sendEmail);
    yield put(sendEmailSuccess(data));
  } catch (e: any) {
    yield put(sendEmailError(e.response.data.errors));
  }
}

export function* restorePasswordWorker({
  payload,
}: RestorePassword): SagaIterator {
  try {
    const { data } = yield call(authService.restorePassword, payload);

    yield put(restorePasswordSuccess());
    yield put(push("/"));
  } catch (e: any) {
    yield put(restorePasswordError(e.response.data.errors));
  }
}

export function* fetchUserByCodeWorker({
  payload,
}: FetchUserByCode): SagaIterator {
  try {
    const { data } = yield call(
      authService.fetchUserByCode,
      payload!.code,
      payload!.pkceVerifier
    );

    yield put(fetchUserByCodeSuccess(data));
    yield put(push("/company"));
  } catch (e: any) {
    yield put(fetchUserByCodeError(e.response.data.errors));
  }
}
