import React from "react";
import { Checkbox, FormControlLabel } from "@mui/material";

import { FieldProps } from "./Field";

interface Props extends FieldProps {
  label: string;
  color?: "primary" | "secondary" | "default";
  disabled?: boolean;
}

export const CheckboxField = ({ name, value, onChange, onBlur, label, color, disabled }: Props): JSX.Element => (
  <FormControlLabel
    label={label}
    name={name}
    value={value}
    onChange={onChange}
    onBlur={onBlur}
    control={<Checkbox color={color} checked={Boolean(value)} />}
    disabled={disabled}
  />
);
