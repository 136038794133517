import makeStyles from "@mui/styles/makeStyles";

const useStyles = makeStyles({
  input: {
    borderRadius: 5,
    height: 38,
  },
});

export default useStyles;
