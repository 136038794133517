import React from "react";
import { TextField } from "@mui/material";

import useStyles from "./styles";

interface Props {
  value: string;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void;
  placeholder?: string;
  name?: string;
  className?: string;
  style?: object;
  label?: string;
}

const NumericUpDown: React.FC<Props> = ({
  value,
  onChange,
  onBlur,
  placeholder,
  name,
  className,
  style,
  label,
}) => {
  const classes = useStyles();

  return (
    <TextField
      label={label}
      type="number"
      name={name}
      className={className || classes.input}
      style={style}
      value={value}
      placeholder={placeholder}
      onChange={onChange}
      onBlur={onBlur}
      variant="outlined"
    />
  );
};

NumericUpDown.defaultProps = {
  placeholder: "",
  name: "",
  className: "",
};

export default NumericUpDown;
