import React, { Suspense, lazy, useState } from "react";
import { Provider } from "react-redux";
import { Switch, Route, Router } from "react-router-dom";
import {
  ThemeProvider,
  Theme,
  StyledEngineProvider,
  createTheme,
  adaptV4Theme,
} from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { history, store } from "libs/redux";
import theme from "libs/ui/theme";
import Loading from "libs/ui/components/layout/Loading";
import {
  AuthMiddleware,
  NonAuthMiddleware,
} from "libs/ui/components/layout/route-middlewares";
import Header from "libs/components/common/Header";
import LeftSideBar from "libs/components/common/LeftSideBar";

import useStyles from "./styles";

declare module "@mui/styles/defaultTheme" {
  interface DefaultTheme extends Theme {}
}

const MainLayout = lazy(() => import("modules/root/pages/MainLayout"));

const AcceptCode = lazy(
  () => import("modules/auth/components/pages/AcceptCode")
);
const Error403Page = lazy(() => import("libs/components/pages/Error403Page"));

const App: React.FC = () => {
  const classes = useStyles();
  const [isDrawerOpened, setIsDropdownOpened] = useState<boolean>(true);

  const toggleDrawer = (): void => setIsDropdownOpened(!isDrawerOpened);

  return (
    <Suspense fallback={<Loading />}>
      <Provider store={store}>
        <StyledEngineProvider injectFirst>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <ThemeProvider theme={createTheme(adaptV4Theme(theme))}>
              <div className={classes.wrapper}>
                <Router history={history}>
                  <Switch>
                    <Route path="/accept-code" exact>
                      <NonAuthMiddleware>
                        <AcceptCode />
                      </NonAuthMiddleware>
                    </Route>

                    <Route path="/unauthorized">
                      <AuthMiddleware>
                        <Error403Page />
                      </AuthMiddleware>
                    </Route>

                    <Route path="/" exact={false}>
                      <AuthMiddleware>
                        <>
                          <Header
                            isDrawerOpened={isDrawerOpened}
                            toggleDrawer={toggleDrawer}
                          />
                          <div className={classes.container}>
                            <LeftSideBar isDrawerOpened={isDrawerOpened} />

                            <MainLayout />
                          </div>
                        </>
                      </AuthMiddleware>
                    </Route>
                  </Switch>
                </Router>
              </div>
            </ThemeProvider>
          </LocalizationProvider>
        </StyledEngineProvider>
      </Provider>
    </Suspense>
  );
};

export default App;
