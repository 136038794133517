import React from "react";
import { CircularProgress, Grid } from "@mui/material";

interface Props {
  width?: string;
  height?: string;
  background?: string | "transparent";
  className?: string;
}

const Loading: React.FC<Props> = ({
  width = "100%",
  height = "100vh",
  background,
  className,
}) => (
  <Grid
    container
    justifyContent="center"
    alignItems="center"
    style={{ width, height, background }}
    className={className || ""}
  >
    <CircularProgress />
  </Grid>
);

export default Loading;
