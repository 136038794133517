import React from "react";
import { MenuItem, TextField, TextFieldProps, InputLabelProps } from "@mui/material";
import { useField } from "formik";

import { Option } from "libs/utils/types";

export type Props = TextFieldProps & {
  noEmptyTitle?: string;
  options: Array<Option>;
  LabelProps?: InputLabelProps;
  isErrorVisible?: boolean;
};

export const SelectField: React.FC<Props> = ({ noEmptyTitle, options, name, isErrorVisible, ...props }) => {
  const [field, meta] = useField<string>(`${name}`);

  return (
    <TextField
      select
      helperText={(isErrorVisible || meta.touched) && meta.error}
      error={(isErrorVisible || meta.touched) && Boolean(meta.error)}
      {...props}
      {...field}
    >
      {noEmptyTitle && <MenuItem value=''>{noEmptyTitle}</MenuItem>}

      {options.map((option: Option, index: number) => (
        <MenuItem key={index} value={option.value}>
          {option.title}
        </MenuItem>
      ))}
    </TextField>
  );
};
