import React from "react";
import { Link, useLocation } from "react-router-dom";
import { ListItem } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import useStyles from "./styles";
import { IconDefinition } from "@fortawesome/fontawesome-common-types";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import classNames from "classnames";

interface Props {
  redirectTo: string;
  text: string;
  isCollapsed?: boolean;
  isSuper?: boolean;
  icon?: IconDefinition;
}

const SideBarLink: React.FC<Props> = ({
  redirectTo,
  text,
  icon,
  isCollapsed,
}) => {
  const classes = useStyles();
  const location = useLocation();

  return (
    <Link to={redirectTo} className={classes.listItemLink}>
      <ListItem
        disableGutters
        className={classNames({
          [classes.isSelected]: redirectTo === location.pathname,
          [classes.collapsedListItem]: isCollapsed,
          [classes.listItem]: !isCollapsed,
        })}
      >
        {icon && (
          <FontAwesomeIcon
            width={14}
            height={14}
            color="rgba(163,175,183,.9)"
            icon={icon as IconProp}
          />
        )}

        {(!isCollapsed || ["City", "Country"].includes(text)) && (
          <span
            className={
              ["City", "Country"].includes(text)
                ? classes.collapsedSidebarText
                : classes.sideBarText
            }
          >
            {text}
          </span>
        )}
      </ListItem>
    </Link>
  );
};

SideBarLink.defaultProps = {
  isCollapsed: false,
  isSuper: true,
};

export default SideBarLink;
