import { SagaIterator } from "redux-saga";
import { takeEvery, all, takeLatest } from "redux-saga/effects";

import { CompanyManagementActionsTypes as c } from "modules/company-management/store/actions/actions";
import * as companyManagementWorkers from "./company-management";

export default function* (): SagaIterator {
  yield all([
    takeLatest(
      c.FETCH_COMPANIES,
      companyManagementWorkers.fetchCompaniesWorker
    ),
    takeLatest(
      c.FETCH_COMPANY_LOGS,
      companyManagementWorkers.fetchCompaniesAdminLogWorker
    ),
    takeEvery(
      c.FETCH_SINGLE_COMPANY,
      companyManagementWorkers.fetchSingleCompanyWorker
    ),
    takeEvery(c.ADD_COMPANY, companyManagementWorkers.addCompanyWorker),
    takeEvery(
      c.DELETE_USER_FROM_COMPANY,
      companyManagementWorkers.deleteUserFromCompanyWorker
    ),
    takeEvery(c.EDIT_COMPANY, companyManagementWorkers.editCompanyWorker),
    takeEvery(
      c.EDIT_COMPANY_MODULES,
      companyManagementWorkers.editCompanyModulesWorker
    ),
    takeEvery(
      c.EDIT_SINGLE_COMPANY_LIMITS,
      companyManagementWorkers.editSingleCompanyLimitsWorker
    ),
    takeEvery(
      c.GET_CURRENT_LIMITS,
      companyManagementWorkers.getCurrentLimitsWorker
    ),
    takeEvery(
      c.EDIT_COMPANY_LIMITS,
      companyManagementWorkers.editCompanyLimitsWorker
    ),
    takeEvery(
      c.EDIT_COMPANY_SETTING,
      companyManagementWorkers.editCompanySettingWorker
    ),
    takeEvery(c.REMOVE_COMPANY, companyManagementWorkers.removeCompanyWorker),
    takeEvery(c.SEND_CONTRACT, companyManagementWorkers.sendContractWorker),
    takeEvery(
      c.SEND_OLD_CONTRACT,
      companyManagementWorkers.sendOldContractWorker
    ),
    takeEvery(
      c.FETCH_CURRENCIES,
      companyManagementWorkers.fetchCurrenciesWorker
    ),
    takeLatest(
      c.FETCH_CHURN_TYPES,
      companyManagementWorkers.fetchChurnTypesWorker
    ),
    takeEvery(c.FETCH_CLEARBIT, companyManagementWorkers.fetchClearbitWorker),
    takeEvery(c.ENABLE_DOMAIN, companyManagementWorkers.enableDomainWorker),
    takeEvery(c.SET_LIMIT, companyManagementWorkers.setLimit),
    takeEvery(c.DISABLE_DOMAIN, companyManagementWorkers.disableDomainWorker),
    takeLatest(c.FETCH_BOTS, companyManagementWorkers.fetchBotsWorker),
    takeEvery(c.SAVE_BOT, companyManagementWorkers.saveBotWorker),
    takeLatest(
      c.FETCH_SALES_PEOPLE,
      companyManagementWorkers.fetchSalesPeopleWorker
    ),
    takeEvery(
      c.FETCH_COMPANY_CONTRACTS,
      companyManagementWorkers.fetchCompanyContractsWorker
    ),
    takeLatest(c.FETCH_INDUSTRIES, companyManagementWorkers.fetchIndustries),
    takeLatest(
      c.FETCH_INDUSTRY_SUB_CLASSES,
      companyManagementWorkers.fetchIndustrySubClasses
    ),
    takeLatest(
      c.FETCH_COMPANIES_TO_SWITCH,
      companyManagementWorkers.switchToCompanyWorker
    ),
    takeLatest(
      c.DUPLICATE_COMPANY,
      companyManagementWorkers.duplicateCompanyWorker
    ),
    takeLatest(
      c.RESEND_ACTIVATION,
      companyManagementWorkers.resendActivationWorker
    ),
    takeEvery(
      c.TOGGLE_COMPANY_ACTIVATED,
      companyManagementWorkers.toggleCompanyActivatedWorker
    ),
    takeLatest(
      c.CREATE_CONTRACT,
      companyManagementWorkers.createContractWorker
    ),
    takeLatest(
      c.SYNC_COMPANY_WITH_PLANHAT,
      companyManagementWorkers.syncCompanyWithPlanhatWorker
    ),
    takeLatest(
      c.SYNC_COMPANY_WITH_YOUNIUM,
      companyManagementWorkers.syncCompanyWithYouniumWorker
    ),
    takeEvery(c.EDIT_CONTRACT, companyManagementWorkers.editContractWorker),
    takeLatest(
      c.BULK_SAVE_CONTRACTS,
      companyManagementWorkers.bulkSaveContractWorker
    ),
    takeLatest(
      c.FETCH_PLATFORMS,
      companyManagementWorkers.fetchPlatformsWorker
    ),
    takeEvery(c.CREATE_PLATFORM, companyManagementWorkers.createPlatformWorker),
    takeEvery(c.EDIT_PLATFORM, companyManagementWorkers.editPlatformWorker),
    takeEvery(c.REMOVE_PLATFORM, companyManagementWorkers.removePlatformWorker),
    takeEvery(
      c.FETCH_CONTRACT_HISTORIES,
      companyManagementWorkers.fetchContractHistoriesWorker
    ),
    takeEvery(
      c.POPULATE_HISTORICAL_LEADS,
      companyManagementWorkers.populateHistoricalLeadsWorker
    ),
    takeEvery(c.GET_AI_MODELS, companyManagementWorkers.getAiModelsWorker),
    takeEvery(
      c.GET_SELECTED_AI_MODELS,
      companyManagementWorkers.getCompanyAiModels
    ),
    takeEvery(
      c.POST_SELECTED_AI_MODELS,
      companyManagementWorkers.postAiModelsWorker
    ),
    takeEvery(
      c.DELETE_SELECTED_AI_MODELS,
      companyManagementWorkers.deleteAiModelWorker
    ),
    takeEvery(
      c.CONNECT_COMPANIES,
      companyManagementWorkers.companyConnectionSaga
    ),
    takeEvery(
      c.GET_SM_CONNECTION,
      companyManagementWorkers.getSMCompanyConnectionSaga
    ),
    takeEvery(
      c.GET_SM_COMPANY_BY_TOKEN,
      companyManagementWorkers.getSMCompanyByToken
    ),
    takeEvery(
      c.DELETE_SM_CONNECTION,
      companyManagementWorkers.deleteSmCompanyConnection
    ),
  ]);
}
