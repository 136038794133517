import React, { useState } from "react";
import { useSelector } from "react-redux";
import {
  Grid,
  List,
  ListItem,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";
import {
  Public as PublicIcon,
  ChevronRight as ChevronRightIcon,
  KeyboardArrowDown as KeyboardArrowDownIcon,
} from "@mui/icons-material";
import {
  faBuilding,
  faMale,
  faLink,
  faLaptop,
  faBook,
  faHandshake,
  faChartLine,
  faUser,
  faBroadcastTower,
} from "@fortawesome/free-solid-svg-icons";

import { getLinks } from "libs/utils/helpers";
import { LinkOptions } from "libs/utils/types";
import SideBarLink from "./SideBarLink";
import { getMeOAuth } from "modules/auth/store/selectors";

import useStyles from "./styles";

interface Props {
  isDrawerOpened: boolean;
}

const LeftSideBar: React.FC<Props> = ({ isDrawerOpened }) => {
  const classes = useStyles();
  const [isExpanded, setIsExpanded] = useState<boolean>(false);
  const isLeadooAdmin = useSelector(getMeOAuth);

  const generalLinks: Array<LinkOptions> = [
    {
      redirectTo: "/company",
      text: "Company Management",
      icon: faBuilding,
      isSuper: false,
    },
    {
      redirectTo: "/free-accounts",
      text: "Free Accounts",
      icon: faUser,
      isSuper: false,
    },
    {
      redirectTo: "/user-management",
      text: "User Management",
      icon: faMale,
      isSuper: true,
    },
    {
      redirectTo: "/shared-bot",
      text: "Media Partner Bot",
      icon: faLink,
      isSuper: true,
    },
    {
      redirectTo: "/integration/errors",
      text: "Integration errors",
      icon: faUser,
      isSuper: true,
    },
    {
      redirectTo: "/issue",
      text: "Broadcast An Issue",
      isSuper: true,
      icon: faBroadcastTower,
    },
  ];

  const renderLink = (link: LinkOptions, index: number): JSX.Element => (
    <SideBarLink key={index} {...link} isCollapsed={!isDrawerOpened} />
  );

  const renderArrow = (): JSX.Element =>
    isExpanded ? (
      <KeyboardArrowDownIcon className={classes.icon} />
    ) : (
      <ChevronRightIcon className={classes.icon} />
    );

  const toggleAccordionExpanded = (): void => setIsExpanded(!isExpanded);

  const generalSuperLinks = getLinks(generalLinks, !!isLeadooAdmin);

  return (
    <List
      className={
        isDrawerOpened ? classes.container : classes.collapsedContainer
      }
    >
      {isDrawerOpened && (
        <ListItem>
          <span className={classes.title}>GENERAL</span>
        </ListItem>
      )}

      {generalSuperLinks.map(renderLink)}

      {isLeadooAdmin && isDrawerOpened && (
        <ListItem>
          <span className={classes.title}>SETTINGS</span>
        </ListItem>
      )}

      {isLeadooAdmin && (
        <Accordion
          square
          expanded={isExpanded}
          onChange={toggleAccordionExpanded}
          className={classes.accordion}
        >
          <AccordionSummary>
            <Grid
              container
              justifyContent={isDrawerOpened ? "space-between" : "center"}
              alignItems="center"
            >
              <PublicIcon className={classes.icon} />

              {isDrawerOpened && (
                <>
                  <span className={classes.regText}>Regional Management</span>

                  {renderArrow()}
                </>
              )}
            </Grid>
          </AccordionSummary>

          <AccordionDetails>
            <div className={classes.regionalLinks}>
              <SideBarLink
                redirectTo="/country"
                text="Country"
                isCollapsed={!isDrawerOpened}
              />

              <SideBarLink
                redirectTo="/city"
                text="City"
                isCollapsed={!isDrawerOpened}
              />
            </div>
          </AccordionDetails>
        </Accordion>
      )}
    </List>
  );
};

export default LeftSideBar;
