import React, { ReactNode, useEffect, useState } from "react";
import { Checkbox, MenuItem } from "@mui/material";
import { ArrowDropDown, ArrowDropUp } from "@mui/icons-material";

import useStyles from "./styles";

type item = {
  label: string;
  value: string;
  render: () => ReactNode;
};

interface Props {
  items: item[];
  selectedItems: string[];
  onChange: (item: item) => void;
  className?: string;
}

const SearchSelect: React.FC<Props> = ({
  items,
  selectedItems,
  onChange,
  className,
}) => {
  const classes = useStyles();
  const [opened, setOpened] = useState<boolean>(false);
  const [searchValue, setSearchValue] = useState<string>("");

  const handleOpenClick = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
    setOpened(true);
  };

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(e.target.value);
  };

  useEffect(() => {
    const handleClickOutside = (e: Event) => {
      e.stopPropagation();
      setOpened(false);
    };
    window.addEventListener("click", handleClickOutside);
    return () => {
      window.removeEventListener("click", handleClickOutside);
    };
  }, []);

  const selectAll = () => {
    onChange({ label: "Select all", value: "select-all", render: () => <></> });
  };

  const selectedItemsLabels: string[] = items
    .filter((item) => selectedItems.includes(item.value))
    .map((item) => item.label);


  return (
    <div
      onClick={handleOpenClick}
      className={`${classes.searchSelect} ${className}`}
    >
      <input
        readOnly
        value={selectedItemsLabels.join(", ")}
        className={classes.selectedElements}
      />
      {opened ? (
        <ArrowDropUp className={classes.icon} />
      ) : (
        <ArrowDropDown className={classes.icon} />
      )}
      {opened && (
        <div className={classes.searchMenu}>
          <input
            onChange={handleSearch}
            className={classes.searchInput}
            placeholder={"Search..."}
          />
          {
            !searchValue && <MenuItem onClick={selectAll} className={classes.selectItem}>
              <Checkbox
                checked={selectedItems.length === items.length}
                readOnly
                color={"primary"}
              />{" "}
              Select all
            </MenuItem>
          }
          {items
            .filter((item) =>
              item.label.toLowerCase().includes(searchValue.toLowerCase()),
            )
            .map((item, itemKey) => {
              const handleChange = () => onChange(item);
              return (
                <MenuItem
                  className={classes.selectItem}
                  onClick={handleChange}
                  key={itemKey}
                >
                  <Checkbox
                    checked={selectedItems.includes(item.value)}
                    readOnly
                    color={"primary"}
                  />{" "}
                  {item.render()}
                </MenuItem>
              );
            })}
        </div>
      )}
    </div>
  );
};

export default SearchSelect;
