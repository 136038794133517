import makeStyles from "@mui/styles/makeStyles";

const useStyles = makeStyles({
  searchSelect: {
    position: "relative",
    width: 270,
  },
  selectedElements: {
    width: "100%",
    padding: "9px 24px 9px 16px",
    borderRadius: 5,
    cursor: "default",
    border: "1px solid #C5CCD7",
  },
  searchMenu: {
    position: "absolute",
    maxHeight: 220,
    backgroundColor: "#fff",
    borderRadius: 5,
    border: "1px solid #eee",
    borderTop: "none",
    width: "100%",
    overflowY: "auto",
    top: "calc(100% + 6px)",
  },
  searchInput: {
    display: "block",
    width: "100%",
    marginBottom: "0.5rem",
    padding: "9px 16px",
    outlineColor: "#f6f6f6",
    border: "none",
    borderBottom: "1px solid #f6f6f6",
  },
  selectItem: {
    paddingLeft: 0,
  },
  icon: {
    position: "absolute",
    right: 2,
    top: "50%",
    transform: "translateY(-50%)",
  },
});

export default useStyles;
