import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(() => ({
  wrapper: {
    display: "flex",
    flexDirection: "column",
    backgroundColor: "#F1F4F5",
    minHeight: "calc(100vh - 66px)",
  },
  container: {
    display: "flex",
    minHeight: "calc(100vh - 66px)",
    flexWrap: "nowrap",
  },
  "@global": {
    "*": {
      boxSizing: "border-box",
      margin: 0,
      padding: 0,
      fontFamily: "Roboto,Sans-serif",
    },
  },
}));

export default useStyles;
