import { SagaIterator } from "redux-saga";
import { put, call } from "redux-saga/effects";
import { push } from "react-router-redux";

import { RegionalManagementService } from "modules/region-management/services";
import {
  createCityError,
  createCitySuccess,
  createCountryError,
  createCountrySuccess,
  editCityError,
  editCitySuccess,
  fetchCitiesError,
  fetchCitiesSuccess,
  fetchCountriesError,
  fetchCountriesSuccess,
  fetchLanguagesError,
  fetchLanguagesSuccess,
  fetchSingleCityError,
  fetchSingleCitySuccess,
  removeCityError,
  removeCitySuccess,
} from "modules/region-management/store/actions/actionCreators";
import {
  CreateCountry,
  FetchSingleCity,
  CreateCity,
  RemoveCity,
  EditCity,
} from "modules/region-management/store/actions/actionTypes";

const regionalManagementService: RegionalManagementService =
  RegionalManagementService.getInstance();

export function* fetchCountriesWorker(): SagaIterator {
  try {
    const { data } = yield call(regionalManagementService.fetchCountries);
    yield put(fetchCountriesSuccess(data));
  } catch (e) {
    yield put(fetchCountriesError(e.response.data.message));
  }
}

export function* createCountryWorker({ payload }: CreateCountry): SagaIterator {
  try {
    const { data } = yield call(
      regionalManagementService.createCountry,
      payload!.data
    );

    yield put(createCountrySuccess(data));
    yield put(push("/country"));
  } catch (e) {
    yield put(createCountryError(e.response.data.message));
  }
}

export function* fetchCitiesWorker(): SagaIterator {
  try {
    const { data } = yield call(regionalManagementService.fetchCities);
    yield put(fetchCitiesSuccess(data));
  } catch (e) {
    yield put(fetchCitiesError(e.response.data.message));
  }
}

export function* fetchSingleCityWorker({
  payload,
}: FetchSingleCity): SagaIterator {
  try {
    const { data } = yield call(
      regionalManagementService.fetchSingleCity,
      payload.data.cityId
    );
    yield put(fetchSingleCitySuccess(data));
  } catch (e) {
    if (e.response.status === 404) {
      yield put(push("/city"));
    }

    yield put(fetchSingleCityError(e.response.data.message));
  }
}

export function* createCityWorker({ payload }: CreateCity): SagaIterator {
  try {
    const { data } = yield call(
      regionalManagementService.createCity,
      payload!.data
    );

    yield put(createCitySuccess(data));
    yield put(push("/city"));
  } catch (e) {
    yield put(createCityError(e.response.data.message));
  }
}

export function* removeCityWorker({ payload }: RemoveCity): SagaIterator {
  try {
    const { data } = yield call(
      regionalManagementService.removeCity,
      payload!.data.cityId
    );
    yield put(removeCitySuccess(data));
  } catch (e) {
    yield put(removeCityError(e.response.data.message));
  }
}

export function* editCityWorker({ payload }: EditCity): SagaIterator {
  try {
    const { data } = yield call(
      regionalManagementService.editCity,
      payload.data
    );

    yield put(editCitySuccess(data));
    yield put(push("/city"));
  } catch (e) {
    yield put(editCityError(e.response.data.message));
  }
}

export function* fetchLanguagesWorker(): SagaIterator {
  try {
    const { data } = yield call(regionalManagementService.fetchLanguages);
    yield put(fetchLanguagesSuccess(data));
  } catch (e) {
    yield put(fetchLanguagesError(e.response.data.message));
  }
}
