import makeStyles from "@mui/styles/makeStyles";

const useStyles = makeStyles({
  container: {
    flex: 1,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  message: {
    textAlign: "center",
    fontWeight: 300,
  },
});

export default useStyles;
