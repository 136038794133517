import axios, { AxiosInstance } from "axios";

import { AuthService } from "modules/auth/services";

export const createHTTPClient = (): AxiosInstance => {
  const http = axios.create({
    baseURL: process.env.REACT_APP_BASE_URL,
    timeout: 20000,
  });

  configureAxiosJWT(http);
  configureAxiosHeaders(http);

  return http;
};

export const configureAxiosJWT = (http: AxiosInstance): void => {
  http.interceptors.response.use(
    (res) => res,
    (error) => {
      const authService = AuthService.getInstance();

      if (error?.response?.status === 401 && AuthService.getRefreshState()) {
        authService.refreshToken(
          localStorage.getItem("refreshToken") as string
        );
      }

      if (error?.response?.status === 403) {
        window.sessionStorage.setItem("redirectUrl", window.location.pathname);
        window.location.href = "/unauthorized";
      }

      return Promise.reject(error);
    }
  );
};

export const configureAxiosHeaders = (http: AxiosInstance): void => {
  http.interceptors.request.use(
    (config) => {
      const token = localStorage.getItem("jwt");

      if (token && config.headers) {
        config.headers.Authorization = `Bearer ${token}`;
      }

      return config;
    },
    (error) => Promise.reject(error)
  );
};
