import React, { ReactElement } from "react";
import { Typography } from "@mui/material";

import Loading from "libs/ui/components/layout/Loading";
import { Company } from "libs/models";

import useStyles from "./styles";

interface Props {
  children: ReactElement;
  companies?: Array<Company>;
  isFetching?: boolean;
  message: string;
  style?: object;
  className?: string;
}

const PendingView: React.FC<Props> = ({
  children,
  companies,
  isFetching = false,
  message,
  style,
  className,
}) => {
  const classes = useStyles();

  if (isFetching) {
    return (
      <div className={`${classes.container} ${className}`} style={style}>
        <Loading />
      </div>
    );
  }

  if (message.length !== 0 || (companies && companies.length === 0)) {
    return (
      <div className={`${classes.container} ${className}`} style={style}>
        <Typography variant="h3" className={classes.message}>
          {message.length !== 0 ? message : "Search for companies first!"}
        </Typography>
      </div>
    );
  }

  return children;
};

export default PendingView;
